import React from 'react';
import Head from 'next/head';

import ErrorPage from '@zola/zola-ui/src/components/ErrorPage';
import Footer from '@zola/zola-ui/src/components/Footer/Footer';

export type CustomErrorPageProps = {
  statusCode?: number;
};

const CustomErrorPage = ({ statusCode }: CustomErrorPageProps): JSX.Element => {
  const pageMetaTitle = statusCode === 404 ? 'Not found' : 'Error occurred';

  return (
    <div>
      <Head>
        <title>{pageMetaTitle} - Zola</title>
      </Head>
      {/* <UnifiedNavV2 defaultTab="HOME_STORE" /> */}
      <ErrorPage statusCode={statusCode || 404} />
      <Footer />
    </div>
  );
};

export default CustomErrorPage;
